import { ArrowRight, Desktop, PhoneCall, SunHorizon, Users } from '@phosphor-icons/react';
import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { Box, Grid, useMediaQuery, useTheme, styled, css } from '@mui/material';
import { graphql } from 'gatsby';
import { SwLatestContent } from '@lib/components/SwLatestContent';
import { SwRecordCard } from '@lib/components/SwRecordCard';
import { grey5, grey3, white } from '@lib/components/bedrock/SwColors';
import SwButton, { SwButtonKind } from '@lib/components/bedrock/button/SwButton';
import SwFlexBox from '@lib/components/bedrock/layout/SwFlexBox';
import SwLine from '@lib/components/bedrock/layout/SwLine';
import SwSpacer from '@lib/components/bedrock/layout/SwSpacer';
import SwLink from '@lib/components/bedrock/typography/SwLink';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import SwWebsiteContainer from '@lib/components/layout/SwWebsiteContainer';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import FlashBanner from '@app/common/components/FlashBanner';
import Seo from '@app/common/components/Seo';
import animatedLogo from '../assets/images/Sweepey.gif';
import foundersPhoto from '../assets/images/founders-photo.jpg';
import teamPhoto from '../assets/images/team-photo.jpg';
import SwContainer from '../components/v1/SwContainer';
import SwHorizontalList from '../components/v1/SwHorizontalList';
import SwLayout from '../components/v1/SwLayout';
import SwTeamTailorJobs from '../components/v1/SwTeamtailorJobs';
import SwSubMenu from '../components/v1/website/SwSubMenu';
import SwTopNav from '../components/v1/website/SwTopNav';
import SwWebsiteFooter from '../components/v1/website/SwWebsiteFooter';
import SwSectionFeatures from '../components/v1/website/features/SwSectionFeatures';

const StyledHeader = styled(Grid)<{ isSmallScreen: boolean }>`
    padding-top: ${({ theme }) => theme.spacing(8)};
    ${({ isSmallScreen, theme }) =>
        !isSmallScreen &&
        css(`
            padding-bottom: ${theme.spacing(8)};
        `)}
`;

const StyledLogo = styled('div')<{ isSmallScreen: boolean }>`
    background-color: ${grey5};
    border-radius: 8px;
    width: 100%;
    height: ${({ isSmallScreen }) => (isSmallScreen ? '200px' : '100%')};
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const StyledBox = styled(Box)`
    background-color: ${grey5};
    border-radius: 8px;
    padding: ${({ theme }) => theme.spacing(2)};
`;

const StyledImage = styled('img')`
    border-radius: 8px;
`;

enum Sections {
    WORKING = 'workingAtSweep',
    ROLES = 'openRoles'
}

const JobsPage = ({ data }) => {
    const { subtitle, title } = data.page;
    const jobs = data.jobs.nodes;
    const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('md'));
    const workingAtSweepRef = useRef(null);
    const openRolesRef = useRef(null);
    const [isVisibleOb, setIsVisibleOb] = useState(null);
    const { t } = useTranslation();

    const isVisible = (inView: boolean, entry: any) => {
        setIsVisibleOb({ inView: inView, entry: entry });
    };

    const listBenefits = [
        <Box display={'flex'}>
            <Users size={24} />
            <SwSpacer direction={'horizontal'} spacing={1} />
            <SwTypography color={grey3}>{t('jobs_page.benefit_1')}</SwTypography>
        </Box>,
        <Box display={'flex'}>
            <SunHorizon size={24} />
            <SwSpacer direction={'horizontal'} spacing={1} />
            <SwTypography color={grey3}>{t('jobs_page.benefit_2')}</SwTypography>
        </Box>,
        <Box display={'flex'}>
            <PhoneCall size={24} />
            <SwSpacer direction={'horizontal'} spacing={1} />
            <SwTypography color={grey3}>{t('jobs_page.benefit_3')}</SwTypography>
        </Box>,
        <Box display={'flex'}>
            <Desktop size={24} />
            <SwSpacer direction={'horizontal'} spacing={1} />
            <SwTypography color={grey3}>{t('jobs_page.benefit_4')}</SwTypography>
        </Box>
    ];

    return (
        <>
            <SwTopNav
                activePage={WEBSITE_MENU_ITEM.jobs.key}
                highlightedPage={data.highlightedPage?.nodes?.at(0)}
                latestUpdates={data.latestArticles.nodes}
            />
            <SwWebsiteContainer>
                <SwLayout>
                    <FlashBanner banner={data.flashBanners.edges[0].node} />
                    <SwContainer withMaxWidth={true}>
                        <StyledHeader container={true} isSmallScreen={isSmallScreen}>
                            <Grid item={true} md={3} xs={11}>
                                <SwTypography component={'h1'} variant={'h3'}>
                                    {title}
                                    <SwSpacer direction={'vertical'} spacing={2} />
                                    <SwTypography color={grey3}>{subtitle}</SwTypography>
                                    <SwSpacer direction={'vertical'} spacing={2} />
                                </SwTypography>
                            </Grid>
                            <Grid item={true} md={1} xs={12} />
                            <Grid item={true} md={8} xs={12}>
                                <StyledImage alt={'Team Photo'} height={'100%'} src={teamPhoto} width={'100%'} />
                            </Grid>
                        </StyledHeader>
                    </SwContainer>

                    <SwSubMenu
                        isVisibleEntry={isVisibleOb}
                        refs={[workingAtSweepRef, openRolesRef]}
                        tabs={[t('jobs_page.sub_nav_item_2'), t('jobs_page.sub_nav_item_1')]}
                        tabsId={[Sections.WORKING, Sections.ROLES]}
                        withMaxWidth={true}
                    />

                    <SwSpacer direction={'vertical'} spacing={4} />

                    <InView as={'div'} id={Sections.WORKING} rootMargin={'-64px 0px 0px 0px'} onChange={isVisible}>
                        <div ref={workingAtSweepRef}>
                            <SwContainer withMaxWidth={true}>
                                <Box display={'flex'} flexDirection={'column'}>
                                    <Grid container={true}>
                                        <SwSectionFeatures
                                            features={[
                                                {
                                                    title: `${t('jobs_page.feature_1_title')} 🔧`,
                                                    description: (
                                                        <SwTypography color={grey3}>
                                                            <Trans i18nKey={'jobs_page.feature_1_content'}>
                                                                <SwLink
                                                                    color={grey3}
                                                                    href={'https://www.sweep.net/'}
                                                                    useNewTab={false}
                                                                />
                                                            </Trans>
                                                        </SwTypography>
                                                    )
                                                },
                                                {
                                                    title: `${t('jobs_page.feature_2_title')} 👫`,
                                                    description: (
                                                        <SwTypography color={grey3}>
                                                            {t('jobs_page.feature_2_content_1')}
                                                            <SwSpacer direction={'vertical'} spacing={2} />
                                                            {t('jobs_page.feature_2_content_2')}
                                                        </SwTypography>
                                                    )
                                                },
                                                {
                                                    title: `${t('jobs_page.feature_3_title')} 🙌`,
                                                    description: (
                                                        <SwTypography color={grey3}>
                                                            <Trans i18nKey={'jobs_page.feature_3_content'}>
                                                                <SwLink
                                                                    color={grey3}
                                                                    href={'https://www.sweep.net/about'}
                                                                    useNewTab={false}
                                                                />
                                                            </Trans>
                                                        </SwTypography>
                                                    )
                                                },
                                                {
                                                    fullHeight: true,
                                                    description: (
                                                        <StyledLogo isSmallScreen={isSmallScreen}>
                                                            <img
                                                                alt={'animated Logo'}
                                                                src={animatedLogo}
                                                                width={'100%'}
                                                            />
                                                        </StyledLogo>
                                                    )
                                                }
                                            ]}
                                            titleHeading={2}
                                        />
                                    </Grid>

                                    <SwLine color={grey5} direction={'horizontal'} spacing={4} />

                                    <Grid container={true} spacing={4}>
                                        <Grid item={true} md={6} xs={12}>
                                            <StyledImage alt={'Founders photo'} src={foundersPhoto} width={'100%'} />
                                        </Grid>
                                        <Grid item={true} md={6}>
                                            <SwTypography component={'h2'} variant={'h4'}>
                                                {t('jobs_page.section_1_title')}
                                            </SwTypography>

                                            <SwSpacer direction={'vertical'} spacing={2} />

                                            <Box width={isSmallScreen ? '100%' : '80%'}>
                                                <SwTypography color={grey3}>
                                                    {t('jobs_page.reason_to_join_1')}
                                                </SwTypography>
                                            </Box>

                                            <SwLine color={grey5} direction={'horizontal'} spacing={1} />

                                            <Box width={isSmallScreen ? '100%' : '80%'}>
                                                <SwTypography color={grey3}>
                                                    {t('jobs_page.reason_to_join_2')}
                                                </SwTypography>
                                            </Box>

                                            <SwLine color={grey5} direction={'horizontal'} spacing={1} />

                                            <Box width={isSmallScreen ? '100%' : '80%'}>
                                                <SwTypography color={grey3}>
                                                    {t('jobs_page.reason_to_join_3')}
                                                </SwTypography>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <SwSpacer direction={'vertical'} spacing={4} />

                                    <Grid container={true}>
                                        <StyledBox width={'100%'}>
                                            <Box
                                                alignItems={isSmallScreen ? 'flex-start' : 'center'}
                                                display={'flex'}
                                                flexDirection={isSmallScreen ? 'column' : 'row'}
                                                gap={!isSmallScreen && '16px'}
                                            >
                                                <SwTypography component={'h3'} variant={'h4'}>
                                                    {t('jobs_page.section_2_title')} 😋
                                                </SwTypography>
                                                <SwTypography color={grey3}>
                                                    {t('jobs_page.section_2_subtitle')}
                                                </SwTypography>
                                            </Box>

                                            <SwLine color={white} direction={'horizontal'} spacing={2} />

                                            <SwHorizontalList
                                                items={listBenefits}
                                                maxWidth={isSmallScreen ? '80vw' : '100vw'}
                                                withArrows={true}
                                                withBorders={true}
                                            />
                                        </StyledBox>
                                    </Grid>
                                </Box>
                            </SwContainer>
                        </div>
                    </InView>

                    <InView as={'div'} id={Sections.ROLES} rootMargin={'-64px 0px 0px 0px'} onChange={isVisible}>
                        <SwLine color={grey5} direction={'horizontal'} spacing={4} />

                        <div ref={openRolesRef}>
                            <SwContainer withMaxWidth={true}>
                                <SwFlexBox flexDirection={'column'}>
                                    <SwTypography component={'h2'} variant={'h3'}>
                                        {t('jobs_page.section_3_title')}
                                        <SwSpacer direction={'vertical'} spacing={2} />
                                    </SwTypography>
                                    <SwTypography color={grey3}>{t('jobs_page.section_3_subtitle')}</SwTypography>
                                </SwFlexBox>
                            </SwContainer>

                            <SwLine color={grey5} direction={'horizontal'} spacingBottom={2} spacingTop={4} />

                            <SwContainer withMaxWidth={true}>
                                <Box
                                    alignItems={'center'}
                                    display={'flex'}
                                    justifyContent={'space-between'}
                                    width={'100%'}
                                >
                                    <SwTypography color={grey3}>
                                        {t('jobs_page.open_roles_count', { count: jobs.length })}
                                    </SwTypography>
                                </Box>
                            </SwContainer>

                            <SwLine color={grey5} direction={'horizontal'} spacing={2} />

                            <SwTeamTailorJobs jobs={jobs} />

                            <SwSpacer direction={'vertical'} spacing={8} />

                            <SwContainer withMaxWidth={true}>
                                <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                                    <Grid container={true}>
                                        <StyledBox width={'100%'}>
                                            <SwTypography component={'h3'} variant={'h4'}>
                                                {t('jobs_page.can_t_find_question')}
                                            </SwTypography>
                                            <SwSpacer direction={'vertical'} spacing={1} />
                                            <SwTypography color={grey3}>
                                                {t('jobs_page.general_application_text')}
                                            </SwTypography>

                                            <SwSpacer direction={'vertical'} spacing={2} />

                                            <SwButton
                                                kind={SwButtonKind.Secondary}
                                                startIcon={<ArrowRight />}
                                                onClick={() =>
                                                    window.open('https://sweep.teamtailor.com/connect', '_blank')
                                                }
                                            >
                                                {t('jobs_page.general_application_action')}
                                            </SwButton>
                                        </StyledBox>

                                        <SwLine color={grey5} direction={'horizontal'} spacing={5} />

                                        <Grid item={true} md={4}>
                                            <SwTypography variant={'h3'}>{t('jobs_page.section_4_title')}</SwTypography>
                                        </Grid>

                                        <Grid item={true} md={4}>
                                            <SwTypography color={grey3}>
                                                <Trans i18nKey={'jobs_page.section_4_subtitle'}>
                                                    <SwLink
                                                        color={grey3}
                                                        href={'https://www.sweep.net/blog'}
                                                        useNewTab={false}
                                                    />
                                                </Trans>
                                            </SwTypography>
                                        </Grid>

                                        <SwLine color={grey5} direction={'horizontal'} spacing={4} />
                                    </Grid>
                                </Box>
                            </SwContainer>

                            <SwContainer noGutter={isSmallScreen} withMaxWidth={true}>
                                <SwLatestContent
                                    content={data.latestArticles.nodes}
                                    renderItems={(article) => (
                                        <SwRecordCard
                                            baseUrl={WEBSITE_MENU_ITEM[article.category.usage.toLowerCase()]?.link}
                                            key={article.id}
                                            record={article}
                                            titleHeading={'h3'}
                                            width={{ xs: 300, md: 'auto' }}
                                        />
                                    )}
                                />
                            </SwContainer>
                        </div>
                    </InView>
                    <SwSpacer direction={'vertical'} spacing={4} />
                    <SwWebsiteFooter hideNewsletter={true} newsletterForm={data.newsletterForm} />
                </SwLayout>
            </SwWebsiteContainer>
        </>
    );
};

export const Head = ({
    location: { pathname },
    data: {
        page: { seo }
    }
}) => (
    <Seo
        description={seo.description}
        image={seo.image?.url}
        noIndex={seo.noIndex}
        pathname={pathname}
        title={seo.title}
    />
);

export const pageQuery = graphql`
    query getJobsPageInformation($id: String, $locale: GraphCMS_Locale) {
        newsletterForm: hubspotForm(name: { eq: "Newsletter Sign-Up" }) {
            ...Essentials_HubspotForm
        }
        latestArticles: allGraphCmsArticle(
            filter: {
                locale: { eq: $locale }
                actualLocale: { eq: $locale }
                industry: { id: { eq: null } }
                seo: { noIndex: { ne: true } }
            }
            sort: { order: DESC, fields: published }
            limit: 4
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
        highlightedPage: allGraphCmsPage(
            limit: 1
            filter: { isNewPage: { eq: true } }
            sort: { order: DESC, fields: createdAt }
        ) {
            nodes {
                ...HighlightedPage_GraphCMS_Page
            }
        }
        flashBanners: allGraphCmsFlashBanner(
            sort: { fields: createdAt, order: DESC }
            filter: { locale: { eq: $locale } }
            limit: 1
        ) {
            edges {
                node {
                    ...Essentials_GraphCMS_FlashBanner
                }
            }
        }
        jobs: allTeamtailorJob {
            nodes {
                title
                links {
                    careersite_job_apply_iframe_url
                }
            }
        }
        page: graphCmsPage(id: { eq: $id }) {
            id
            remoteId
            slug
            title
            subtitle
            seo {
                description
                noIndex
                title
                image {
                    url
                }
            }
        }
    }
`;

export default JobsPage;
