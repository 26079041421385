import { ArrowLeft, ArrowRight } from '@phosphor-icons/react';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { css, styled } from '@mui/material';
import { white, grey5RGB, grey5 } from '../../lib/components/bedrock/SwColors';

const StyledOuterBox = styled('div')<{ maxWidth: string }>`
    position: relative;
    ${({ maxWidth }) => css`
        max-width: ${maxWidth ?? '80vw'};
    `}
`;

const StyledInnerBox = styled('div')<{ padding: string }>`
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: ${({ padding }) => padding};

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
`;

const StyledContent = styled('div')<{ withBorders: boolean; isLast: boolean; gapBetweenItems?: string }>`
    flex: 0 0 auto;

    ${({ withBorders, isLast, gapBetweenItems, theme }) =>
        withBorders
            ? css(`
                  &:not(:last-child) {
                      border-right: 1px solid ${white};
                      padding-right: ${theme.spacing(2)};
                  }

                  &:not(:first-child) {
                      padding-left: ${theme.spacing(2)};
                  }

                  ${isLast ? 'padding-right: 0;' : ''}
              `)
            : css(`
                  padding-right: ${isLast ? theme.spacing(2) : gapBetweenItems ? gapBetweenItems : theme.spacing(2)};
              `)}
`;

const sharedIconStyles = css`
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    color: ${white};
    width: 100px;

    &:hover {
        cursor: pointer;
    }
`;

const StyledLeftArrow = styled('div')`
    ${sharedIconStyles};
    justify-content: flex-start;
    left: 0;
    background: ${grey5};
    background: linear-gradient(90deg, rgba(${grey5RGB.join(',')}, 1) 0%, rgba(${grey5RGB.join(',')}, 0) 100%);
`;

const StyledRightArrow = styled('div')`
    ${sharedIconStyles};
    justify-content: flex-end;
    right: 0;
    background: ${grey5};
    background: linear-gradient(90deg, rgba(${grey5RGB.join(',')}, 0) 0%, rgba(${grey5RGB.join(',')}, 1) 100%);
`;

const SwHorizontalList = (props) => {
    const innerBoxRef = useRef<HTMLDivElement>();

    const handleLeftArrowIconClickEvent = useCallback(() => {
        if (innerBoxRef.current) {
            innerBoxRef.current?.scrollBy({
                behavior: 'smooth',
                left: -300
            });
        }
    }, [innerBoxRef]);

    const handleRightArrowIconClickEvent = useCallback(() => {
        if (innerBoxRef.current) {
            innerBoxRef.current?.scrollBy({
                behavior: 'smooth',
                left: 300
            });
        }
    }, [innerBoxRef]);

    const [isNeedArrows, setNeedArrows] = useState<boolean>(true);

    useEffect(() => {
        if (innerBoxRef.current) {
            if (innerBoxRef.current.scrollWidth > innerBoxRef.current.clientWidth) {
                setNeedArrows(true);
            } else {
                setNeedArrows(false);
            }
        }
    }, [
        innerBoxRef,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ...(innerBoxRef.current ? [innerBoxRef.current.scrollWidth] : [null]),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ...(innerBoxRef.current ? [innerBoxRef.current.clientWidth] : [null]),
        isNeedArrows,
        setNeedArrows
    ]);

    const [isNeedLeftArrow, setNeedLeftArrow] = useState<boolean>(false);
    const [isNeedRightArrow, setNeedRightArrow] = useState<boolean>(true);

    const handleScrollChangeEvent = (e) => {
        const curScrollPos = e.currentTarget.scrollLeft;
        const maxScrollPos = e.currentTarget.scrollWidth - e.currentTarget.clientWidth;

        if (curScrollPos === 0) {
            setNeedLeftArrow(false);
        } else if (curScrollPos >= maxScrollPos) {
            setNeedRightArrow(false);
        } else {
            setNeedLeftArrow(true);
            setNeedRightArrow(true);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if (props.scrollToTheEnd) {
                innerBoxRef.current?.scrollBy({
                    behavior: 'smooth',
                    left: 500
                });
            }
        }, 250);
    }, [props.scrollToTheEnd]);

    const renderItem = (): ReactNode => {
        return props.items.map((item: string, index: number) => (
            <StyledContent
                gapBetweenItems={props.gapBetweenItems}
                isLast={index + 1 === props.items.length}
                key={index}
                withBorders={props.withBorders}
            >
                {item}
            </StyledContent>
        ));
    };

    return (
        <StyledOuterBox maxWidth={props.maxWidth}>
            <StyledInnerBox padding={props.padding} ref={innerBoxRef} onScroll={handleScrollChangeEvent}>
                {renderItem()}
            </StyledInnerBox>

            {props.withArrows && isNeedArrows && (
                <>
                    {isNeedLeftArrow && (
                        <StyledLeftArrow>
                            <ArrowLeft onClick={handleLeftArrowIconClickEvent} />
                        </StyledLeftArrow>
                    )}
                    {isNeedRightArrow && (
                        <StyledRightArrow>
                            <ArrowRight onClick={handleRightArrowIconClickEvent} />
                        </StyledRightArrow>
                    )}
                </>
            )}
        </StyledOuterBox>
    );
};

export default SwHorizontalList;
