import { ArrowRight } from '@phosphor-icons/react';
import React from 'react';
import { Box, Grid, styled } from '@mui/material';
import { grey5, white } from '@lib/components/bedrock/SwColors';
import { SwLinkBox, SwLinkOverlay } from '@lib/components/bedrock/navigation/SwLinkBox';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import SwContainer from './SwContainer';

interface SwTeamTailorJobsProps {
    jobs: {
        title: string;
        links: {
            careersite_job_apply_iframe_url: string;
        };
    }[];
}

const StyledJob = styled('div')`
    background-color: ${white};
    border: 1px solid ${grey5};
    padding: ${({ theme }) => theme.spacing(1.5)} ${({ theme }) => theme.spacing(3)};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
        cursor: pointer;
    }
`;

const SwTeamTailorJobs: React.FunctionComponent<SwTeamTailorJobsProps> = (props: SwTeamTailorJobsProps) => {
    return (
        <SwContainer withMaxWidth={true}>
            <Box display={'flex'} flexDirection={'column'} gap={3} justifyContent={'space-between'} width={'100%'}>
                {props.jobs.map((job, index) => (
                    <SwLinkBox>
                        <Grid container={true} justifyContent={'space-between'} key={index} spacing={2}>
                            <Grid item={true} md={12} xs={12}>
                                <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                                    <StyledJob key={index}>
                                        <SwLinkOverlay
                                            href={`${job.links.careersite_job_apply_iframe_url.replace('/applications/new?iframe=true', '')}`}
                                            target={'_blank'}
                                        >
                                            <SwTypography bold={true}>{job.title}</SwTypography>
                                        </SwLinkOverlay>
                                        <ArrowRight />
                                    </StyledJob>
                                </Box>
                            </Grid>
                        </Grid>
                    </SwLinkBox>
                ))}
            </Box>
        </SwContainer>
    );
};

export default SwTeamTailorJobs;
