import * as React from 'react';
import { Box, Grid, GridSpacing, useMediaQuery, useTheme, styled, css } from '@mui/material';
import { grey5, grey6 } from '../../../../lib/components/bedrock/SwColors';
import SwSpacer from '../../../../lib/components/bedrock/layout/SwSpacer';
import SwTypography from '../../../../lib/components/bedrock/typography/SwTypography';

interface SwSectionFeaturesProps {
    features: { title?: string; description: any; fullHeight?: boolean }[];
    background?: boolean;
    spacing?: number;
    titleHeading?: 1 | 2 | 3 | 4;
}

const StyledGrid = styled(Grid)<{ isBelowMd: boolean }>`
    ${({ isBelowMd }) =>
        !isBelowMd &&
        css`
            border-right: 1px solid ${grey5};
            padding-right: 16px;
        `}
    margin-top: ${({ theme }) => theme.spacing(3)};
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    :last-child {
        border-right: none;
        padding-right: 0px;
    }
`;

const StyledBox = styled(Box)<{ background?: boolean }>`
    ${({ background, theme }) =>
        background &&
        css(`
            background-color: ${grey6};
            padding: ${theme.spacing(2)};
            border-radius: 8px;
        `)}
`;

const StyledDescription = styled(Grid)<{ fullHeight?: boolean }>`
    height: ${({ fullHeight }) => (fullHeight ? '100%' : 'auto')};
`;

const SwSectionFeatures: React.FunctionComponent<SwSectionFeaturesProps> = (props) => {
    const isBelowMd = useMediaQuery(useTheme().breakpoints.down('md'));

    return (
        <Grid container={true} spacing={props.spacing ? (props.spacing as GridSpacing) : isBelowMd ? 0 : 4}>
            {props.features.map(function (object, index) {
                return (
                    <StyledGrid
                        container={true}
                        direction={'column'}
                        isBelowMd={isBelowMd}
                        item={true}
                        justifyContent={'flex-start'}
                        key={index}
                        md={3}
                        xs={12}
                    >
                        <StyledBox background={props.background}>
                            {object.title && (
                                <>
                                    <Grid item={true}>
                                        <SwTypography component={`h${props.titleHeading ?? 3}`} variant={'h4'}>
                                            {object.title}
                                        </SwTypography>
                                    </Grid>
                                    <Grid item={true}>
                                        <SwSpacer direction={'vertical'} spacing={2} />
                                    </Grid>
                                </>
                            )}
                            <StyledDescription fullHeight={object.fullHeight} item={true}>
                                {object.description}
                            </StyledDescription>
                        </StyledBox>
                    </StyledGrid>
                );
            })}
        </Grid>
    );
};

export default SwSectionFeatures;
